module.exports = (intl) => {
  return {
    titleMapping: {
      1: intl.formatMessage({id: "Register.Form.Title.Option1"}),
      2: intl.formatMessage({id: "Register.Form.Title.Option2"}),
      3: intl.formatMessage({id: "Register.Form.Title.Option3"}),
    },
    experienceMapping: {
      1: intl.formatMessage({id: "Register.Form.PersonalProfile.Experience.Option1"}),
      2: intl.formatMessage({id: "Register.Form.PersonalProfile.Experience.Option2"}),
      3: intl.formatMessage({id: "Register.Form.PersonalProfile.Experience.Option3"}),
      4: intl.formatMessage({id: "Register.Form.PersonalProfile.Experience.Option4"}),
      5: intl.formatMessage({id: "Register.Form.PersonalProfile.Experience.Option5"}),
    },
    contractTypeMapping: {
      1: intl.formatMessage({id: "Register.Form.PersonalProfile.ContractType.Option1"}),
      2: intl.formatMessage({id: "Register.Form.PersonalProfile.ContractType.Option2"}),
      3: intl.formatMessage({id: "Register.Form.PersonalProfile.ContractType.Option3"}),
      4: intl.formatMessage({id: "Register.Form.PersonalProfile.ContractType.Option4"}),
    },
    studiesLevelMapping: {
      1: intl.formatMessage({id: "Register.Form.PersonalProfile.StudiesLevel.Option1"}),
      2: intl.formatMessage({id: "Register.Form.PersonalProfile.StudiesLevel.Option2"}),
      3: intl.formatMessage({id: "Register.Form.PersonalProfile.StudiesLevel.Option3"}),
      4: intl.formatMessage({id: "Register.Form.PersonalProfile.StudiesLevel.Option4"}),
      5: intl.formatMessage({id: "Register.Form.PersonalProfile.StudiesLevel.Option5"}),
      6: intl.formatMessage({id: "Register.Form.PersonalProfile.StudiesLevel.Option6"}),
      7: intl.formatMessage({id: "Register.Form.PersonalProfile.StudiesLevel.Option7"}),
      8: intl.formatMessage({id: "Register.Form.PersonalProfile.StudiesLevel.Option8"}),
      9: intl.formatMessage({id: "Register.Form.PersonalProfile.StudiesLevel.Option9"}),
    },
    disabledWorkerMapping: {
      "true": intl.formatMessage({id: "Register.Form.PersonalProfile.DisabledWorker.Option1"}),
      "false": intl.formatMessage({id: "Register.Form.PersonalProfile.DisabledWorker.Option2"}),
    },
  }
};
